import React from 'react';
import Layout from "../components/layout";
import styled from "styled-components";
import colors from '../components/theme'

const ThankYou = () => {

    const ThankYouDiv = styled.div`
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 35px;
    font-family: rubik;
    height: 85vh;
    width: 100vw;
    font-weight: 200;
    background-color: ${colors.gray};
    color: #FFFFFF
    `

    return (
        <Layout>
            <ThankYouDiv>
                Thanks for submitting your application!
            </ThankYouDiv>
            
        </Layout>
    )
}

export default ThankYou; 